<template>
  <Head>
    <!-- Social -->
    <meta property="og:image" :content="`${ BASE_URL }og_image_trailer.jpg`">
    <meta property="og:image:type" content="image/jpeg">
    <meta property="og:image:width" content="1200">
    <meta property="og:image:height" content="630">
  </Head>

  <main class="main">
    <picture>
      <source media="(max-width: 599px)" srcset="@/assets/trailer_mobile.jpg, @/assets/trailer_mobile-2x.jpg 2x">
      <source media="(min-width: 600px)" srcset="@/assets/trailer_desktop.jpg, @/assets/trailer_desktop-2x.jpg 2x">
      <img class="bg" src="@/assets/trailer_desktop.jpg" alt="Mao and Rex by the sea">
    </picture>

    <div class="info-wrap">
      <h1 class="title-main">Mao & Tsou</h1>
      <h2 class="title-time">
        <time datetime="2022-08-13T18:00"><span class="title-time__date">2022.08.13</span><span class="title-time__time">Sat. 18:00</span></time>
      </h2>
      <div class="address">
        <a href="https://goo.gl/maps/owPiNYVgiSPJx6SA8" target="_blank">@台北市 孫立人將軍官邸</a>
      </div>
      <AddToCalendarButton class="u-my-6" />
      <div class="notify">敬請等候更進一步的邀約資訊 :)</div>
    </div>
  </main>

  <Footer />
</template>

<script setup>
import { Head } from '@vueuse/head';
const BASE_URL = process.env.BASE_URL;
</script>

<script>
import {
  defineAsyncComponent,
} from 'vue';

export default {
  props: {
    msg: String
  },
  components: {
    AddToCalendarButton: defineAsyncComponent(() => import('@/components/AddToCalendarButton.vue')),
    Footer: defineAsyncComponent(() => import('@/components/Footer.vue'))
  },
};
</script>

<style lang="scss" scoped>
@import '~@/sass/00_abstracts/variables';

.main {
  color: $c-text-default;
  position: relative;
  height: 100vh;
  padding: 30px 30px;
  display: flex;

  @media (min-width: 600px) {
    color: $c-text-white;
    align-items: flex-end;
    padding: 100px;
  }
}

.info-wrap {
  width: 100%;

  @media (min-width: 600px) {
    width: auto;
    padding: 50px;
    text-shadow: 4px 3px 5px $c-text-default;
    backdrop-filter: blur(2px);
    border-radius: 4px;
  }
}

.title-main {
  font-style: italic;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 0;
  font-size: clamp(2rem, 10vw, 5rem);

  @media (min-width: 600px) {
    font-size: 4.8rem;
  }
}

.title-time {
  font-weight: normal;
  font-style: italic;

  &__date {
    font-size: clamp(2rem, 10vw, 5rem);

    @media (min-width: 600px) {
      font-size: 8.4rem;
    }
  }

  &__time {
    padding-left: 0.8em;
    font-size: clamp(1.4rem, 6vw, 3.5rem);

    @media (min-width: 600px) {
      font-size: 3.6rem;
    }
  }
}

.bg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  object-fit: cover;
  z-index: -1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}

.address {
  font-size: clamp(1.4rem, 6vw, 2.2rem);

  @media (min-width: 600px) {
    font-size: 2.4rem;
  }
}

.notify {
  font-size: 1.6rem;
  bottom: 10px;
  left: 0;
  right: 0;
  position: absolute;
  text-align: center;
  color: $c-text-white;

  @media (min-width: 600px) {
    position: static;
    text-align: left;
  }
}
</style>
